import { stringify } from 'query-string';

export const buildSearchParamsString = (params = {}) => {
  if (Object.keys(params).length === 0) return '';
  return stringify(params, { arrayFormat: 'bracket' });
};

export const buildCustomQueryString = (params = {}) => {
  const generateQuery = (key, value) => `${key}=${encodeURIComponent(value)}`;

  return Object.keys(params)
    .map((key) => {
      const value = params[key];

      if (Array.isArray(value)) {
        return value
          .map((element) => generateQuery(`${key}[]`, element))
          .join('&');
      }
      return generateQuery(key, value);
    })
    .join('&');
};

class ApiError {
  constructor({
    message = 'Unknown Error',
    code = 'GeneralError',
    errorType = 'UnknownType',
    errorCode = 'unknown_error',
    fieldErrors = [],
    details = []
  }) {
    this.message = message;
    this.code = code;
    this.errorType = errorType;
    this.fieldErrors = fieldErrors;
    this.details = details;
    this.errorCode = errorCode;
  }

  get json() {
    return {
      message: this.message,
      code: this.code,
      fieldErrors: this.fieldErrors,
      errorType: this.errorType,
      details: this.details,
      errorCode: this.errorCode
    };
  }
}

export const handleApiResponse = async (response) => {
  let errorMessage;
  const content = await response.text();

  if (!content && (response.status === 201 || response.status === 204)) {
    return {
      code: response.status,
      ok: true
    };
  }

  if (!content) {
    // No content
    const code = response.status;

    switch (code) {
      case 403:
        errorMessage = 'Unauthorized';
        break;
      case 404:
        errorMessage = 'Not Found';
        break;
      case 503:
        errorMessage = 'Resource Not Available';
        break;
      default:
        errorMessage = `Error with code - ${code}`;
    }
    return {
      ok: false,
      error: new ApiError({
        message: errorMessage,
        code: response.status
      }).json
    };
  }

  let jsonData = {};
  let errorCode;
  let errorType = '';
  let fieldErrors = [];
  try {
    jsonData = JSON.parse(content);
    if (response.ok) {
      return {
        code: response.status,
        data: jsonData.data,
        ok: true
      };
    }
    errorMessage = jsonData.error.message;
    errorCode = jsonData.error.code;
  } catch (e) {
    errorMessage = 'Something went wrong, please try again.';
    errorCode = 'unknown_error';
  }

  if (jsonData.error && jsonData.error.type) {
    errorType = jsonData.error.type;
  }

  if (jsonData.error && jsonData.error.field_errors) {
    fieldErrors = jsonData.error.field_errors;
  }

  return {
    data: jsonData.data,
    ok: false,
    error: new ApiError({
      message: errorMessage,
      code: response.status,
      errorType,
      errorCode,
      fieldErrors
    }).json
  };
};

export const API_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETED: 'completed',
  FAILED: 'failed'
};

export const buildNestedQueryString = (obj, prefix = '') => {
  const queryString = [];

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const encodedKey = encodeURIComponent(`${prefix}${key}`);

    if (typeof value === 'object' && value !== null) {
      const nestedQueryString = buildNestedQueryString(value, `${encodedKey}[`);
      queryString.push(...nestedQueryString);
    } else {
      const encodedValue = encodeURIComponent(value);
      queryString.push(`${encodedKey}]=${encodedValue}`);
    }
  });

  return queryString;
};

export const buildQueryStringWithComma = (params = {}) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key].join('%2C')}`)
    .join('&');
